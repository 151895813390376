<template>
    <div class="team-index-page">
        <el-tabs class="filter-tabs" v-model="activeFilter" @tab-click="filterClickEvent">
            <el-tab-pane label="团队" name="TEAM" v-if="$checkPermission(`team:listTeam`)"></el-tab-pane>
            <el-tab-pane label="成员" name="MY" v-if="$checkPermission(`team:myTeamMembers`)"></el-tab-pane>
            <el-tab-pane label="成员报价" name="OFFER" v-if="$checkPermission(`team:quotation`)"></el-tab-pane>
            <el-tab-pane label="成员审核" name="AUDIT" v-if="$checkPermission(`team:reviewTeamMember`)"></el-tab-pane>
        </el-tabs>
        <div class="page-tools-content" style="margin-bottom: 0;">
            <div class="left">
                <template v-if="activeFilter === 'OFFER'">
                    <el-button type="primary" size="small" round icon="el-icon-plus" v-permission="`team:quotation:create`" @click="createOfferBtnEvent">新增报价</el-button>
                    <el-button plain size="small" round v-permission="``" @click="showImportDialog = true">批量导入</el-button>
                </template>
                <template v-else>
                    <el-button type="primary" size="small" round icon="el-icon-plus" v-permission="`team:myTeamMembers:invite`" @click="inviteBtnEvent">邀请成员</el-button>
                    <el-button type="primary" size="small" round icon="el-icon-plus" v-permission="`team:listTeam:create`" @click="createTeamBtnEvent">新建团队</el-button>
                </template>
                <el-button round plain size="small" icon="el-icon-refresh-left" :loading="loading" v-permission="`team:refresh`" @click="refreshPage">刷新</el-button>
            </div>
            <div class="right">
                <div style="width:200px;">
                    <el-input placeholder="输入关键词" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div>
                <div class="margin-l-10" style="width:114px;" v-if="['MY'].indexOf(activeFilter) > -1">
                    <el-select v-model="searchForm.team" size="small" placeholder="搜索团队" clearable @change="selectSearchEvent">
                        <template v-for="(item, index) in teamOptions">
                            <el-option :key="index" :value="item.value" :label="item.text"></el-option>
                        </template>
                    </el-select>
                </div>
                <div class="margin-l-10" style="width:114px;" v-if="['MY', 'OFFER'].indexOf(activeFilter) > -1">
                    <el-select v-model="searchForm.role" size="small" placeholder="搜索角色" clearable @change="selectSearchEvent">
                        <template v-for="item in roleOptions">
                            <el-option :key="item.roleId" :value="item.roleId" :label="item.roleName"></el-option>
                        </template>
                    </el-select>
                </div>
                <!-- <div class="margin-l-10" style="width:114px;">
                    <el-select v-model="searchForm.status" size="small" placeholder="搜索状态" clearable @change="selectSearchEvent">
                        <template v-for="item in statusOptions">
                            <el-option :key="item.id" :value="item.id" :label="item.name"></el-option>
                        </template>
                    </el-select>
                </div> -->
            </div>
        </div>
        <template v-if="activeFilter === 'OFFER'">
            <el-table :data="list" style="width: 100%;">
                <el-table-column label="姓名" prop="alias"></el-table-column>
                <el-table-column label="角色" prop="roleDesc"></el-table-column>
                <el-table-column label="语言方向" prop="langDirection"></el-table-column>
                <el-table-column label="任务类型" prop="taskTypeDesc"></el-table-column>
                <el-table-column label="任务单价" prop="price"></el-table-column>
                <!-- <el-table-column label="所属团队" prop="teamName"></el-table-column> -->
                <el-table-column label="添加时间" prop="createTime" width="150"></el-table-column>
                <el-table-column label="操作" width="120">
                    <template slot-scope="scope">
                        <el-tooltip content="编辑" placement="top">
                            <el-button @click="editMemberOfferBtnEvent(scope.row)" v-permission="`team:quotation:update`" type="text"><svg-icon name="ib-edit"></svg-icon></el-button>
                        </el-tooltip>
                        <el-tooltip content="联系方式" placement="top">
                            <el-button @click="handleMoreCommand('view', scope.row)" type="text"><svg-icon name="ib-ecard"></svg-icon></el-button>
                        </el-tooltip>
                        <el-tooltip content="删除" placement="top">
                            <el-button @click="handleMoreCommand('delete', scope.row)" v-permission="`team:quotation:remove`" type="text"><svg-icon name="ib-trash-o"></svg-icon></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </template>
        <template v-else>
            <div class="card-list" style="margin-top: 20px;" v-if="list.length > 0">
                <template v-for="(item, index) in list">
                    <div class="item" :key="index" @click="handleMoreCommand('view', item)">
                        <div class="flex-1 min-w-0">
                            <div class="flex flex-align-center">
                                <template v-if="activeFilter === 'TEAM'">
                                    <el-avatar :size="24">
                                        <svg-icon name="ib-invite-1" className="fonts-18"></svg-icon>
                                    </el-avatar>
                                </template>
                                <template v-else>
                                    <el-avatar :size="24" :src="item.memAvatar" icon="el-icon-user"></el-avatar>
                                </template>
                                <div class="flex-1 margin-l-5 text-weight-600 text-omission">{{item.alias || item.memName || item.teamName}}</div>
                            </div>
                            <div class="margin-t-15 flex flex-align-center fonts-12" v-if="activeFilter === 'TEAM'">
                                <div>团队人数：{{item.totalMember}}</div>
                            </div>
                            <div class="margin-t-15 flex flex-align-center fonts-12" v-else>
                                <div>{{item.roleName || '—'}}</div>
                                <el-divider direction="vertical" class="margin-x-5"></el-divider>
                                <el-tooltip placement="top-start" class="flex-1" v-if="item.teamList?.length">
                                    <div slot="content">
                                        <template v-for="(team, index) in item.teamList">{{team.teamName}}{{index === item.teamList.length - 1 ? '' : ','}}</template>
                                    </div>
                                    <div class="text-omission">
                                        <template v-for="(team, index) in item.teamList">{{team.teamName}}{{index === item.teamList.length - 1 ? '' : ','}}</template>
                                    </div>
                                </el-tooltip>
                                <template v-else>
                                    <div>—</div>
                                </template>
                            </div>
                            <div class="margin-t-25 flex flex-align-center fonts-12" v-if="activeFilter === 'TEAM'">
                                <div>创建时间：{{item.createTime}}</div>
                            </div>
                            <div class="margin-t-25 flex flex-align-center fonts-12" v-else>
                                <svg-icon name="ib-phone"></svg-icon>
                                <span class="margin-l-5">{{item.phone || item.memPhone || '—'}}</span>
                                <el-divider direction="vertical" class="margin-x-8"></el-divider>
                                <div class="email-name">{{item.email || item.memEmail || '—'}}</div>
                            </div>
                        </div>
                        <div :class="{'flex-column': true, 'flex-justify-between': activeFilter === 'AUDIT', 'flex-justify-start': activeFilter === 'MY'}">
                            <!-- <div class="transfer-btn" v-permission="`team:myTeamMembers:grant`" @click.stop="transferBtnEvent(item)" v-if="item.memRoleId === 2">移交</div> -->
                            <template v-if="activeFilter === 'MY'">
                                <div></div>
                            </template>
                            <template v-if="activeFilter === 'AUDIT'">
                                <template v-if="item.inviteStatus === 1">
                                    <div class="audit-btn" v-permission="`team:reviewTeamMember:review`" @click.stop="auditBtnEvent(item)">审核</div>
                                </template>
                                <template v-else>
                                    <div class="status" :class="`audit_${item.inviteStatus}`">{{item.memInviteStatus}}</div>
                                </template>
                            </template>
                            <!-- <div class="status" :class="{'success': item.memInviteStatus === 'joined'}">{{item.memInviteStatus === 'inviting' ? '邀请中' : '已加入'}}</div> -->
                            <el-dropdown placement="bottom" @command="(command)=>handleMoreCommand(command,item)">
                                <div class="action" @click.stop>
                                    <span class="user">
                                        <svg-icon name="ib-invite-1" v-if="activeFilter === 'TEAM'"></svg-icon>
                                        <svg-icon name="ib-user" v-else></svg-icon>
                                    </span>
                                    <span class="more">
                                        <svg-icon name="ib-more"></svg-icon>
                                    </span>
                                </div>
                                <el-dropdown-menu slot="dropdown">
                                    <template v-if="activeFilter === 'MY'">
                                        <el-dropdown-item command="view" v-permission="`team:myTeamMembers:info`">查看联系方式</el-dropdown-item>
                                        <el-dropdown-item command="edit" v-permission="`team:myTeamMembers:edit`">编辑信息</el-dropdown-item>
                                        <template v-if="item.roleId === 2">
                                            <el-dropdown-item command="grant" divided v-permission="`team:myTeamMembers:grant`" style="color:#F56C6C;" >移交</el-dropdown-item>
                                        </template>
                                        <template v-else>
                                            <el-dropdown-item command="delete" divided v-permission="`team:myTeamMembers:delete`" style="color:#F56C6C;">删除</el-dropdown-item>
                                        </template>
                                    </template>
                                    <template v-else-if="activeFilter === 'AUDIT'">
                                        <el-dropdown-item command="delete" v-permission="`team:reviewTeamMember:delete`" style="color:#F56C6C;">删除</el-dropdown-item>
                                    </template>
                                    <template v-else-if="activeFilter === 'TEAM'">
                                        <el-dropdown-item command="view" v-permission="``">查看</el-dropdown-item>
                                        <el-dropdown-item command="edit" v-permission="`team:listTeam:update`">编辑</el-dropdown-item>
                                        <el-dropdown-item command="delete" v-permission="`team:listTeam:remove`" style="color:#F56C6C;">删除</el-dropdown-item>
                                    </template>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                </template>
            </div>
            <div class="none-list-block" v-else>
                <div class="none-files-content">
                    <svg-icon name="ib-empty"></svg-icon>
                    <div class="info" v-if="queryType === 'search'">没有找到内容～</div>
                    <div class="info" v-else>暂无内容～</div>
                </div>
            </div>
        </template>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize">
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
        <el-dialog title="编辑成员" :visible.sync="editDialog.show" destroy-on-close @closed="handlerEditDialogClose" width="430px" top="20px">
            <div class="padding-x-20">
                <el-form size="medium" label-position="top">
                    <el-form-item label="姓名" required class="margin-b-20">
                        <el-input v-model="editDialog.form.name" placeholder="请输入姓名" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="角色" required class="margin-b-20">
                        <el-select v-model="editDialog.form.role" :disabled="editDialog.form.role === 2" placeholder="请选择角色" clearable style="width: 100%">
                            <template v-for="item in roleOptions">
                                <template v-if="editDialog.form.role === 2">
                                    <el-option :key="item.roleId" :value="item.roleId" :label="item.roleName"></el-option>
                                </template>
                                <template v-else>
                                    <el-option :key="item.roleId" :value="item.roleId" :label="item.roleName" v-if="item.roleId !== 2"></el-option>
                                </template>
                            </template>
                        </el-select>
                    </el-form-item>
                    
                    <el-form-item label="手机号" required class="margin-b-20">
                        <el-input v-model="editDialog.form.phone" placeholder="请输入手机号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱" required class="margin-b-20">
                        <el-input v-model="editDialog.form.email" placeholder="请输入邮箱" clearable></el-input>
                    </el-form-item>

                    <el-form-item label="所属团队" required class="margin-b-20">
                        <el-select v-model="editDialog.form.teamList" placeholder="请选择团队" multiple collapse-tags-tooltip :max-collapse-tags="3" style="width: 100%">
                        <el-option v-for="item in teamOptions" :key="item.value" :value="item.value" :label="item.text"></el-option>
                        </el-select>
                    </el-form-item>

                </el-form>
            </div>
            
            <div slot="footer" class="text-center">
                <el-button size="mini" plain round @click="editDialog.show = false">取消</el-button>
                <el-button type="primary" size="mini" round :loading="editDialog.loading" @click="submitEditDialogForm">保存</el-button>
            </div>
        </el-dialog>
        <el-dialog title="邀请成员" :visible.sync="inviteDialog.show" destroy-on-close @open="generateInviteLink" width="430px" top="20px">
            <div class="invite-dialog-content">
                <div>其他用户可通过邀请链接申请加入团队<br>审核通过后成为正式成员</div>
                <div class="margin-t-20 margin-b-10">
                    <el-input readonly size="medium" placeholder="请输入内容" v-model="inviteDialog.url">
                        <el-button type="primary" slot="append" @click="copyShareLink">复制链接</el-button>
                    </el-input>
                </div>
            </div>
        </el-dialog>
        <el-dialog title="身份移交" :visible.sync="transferDialog.show" destroy-on-close @closed="handlerTransferDialogClose" class="none-padding-body" width="430px" top="20px">
            <div class="padding-20 text-center" v-if="transferDialog.submited">
                <div class="color-success fonts-20"><i class="el-icon-success"></i></div>
                <div class="margin-t-15 fonts-15">权限变更成功</div>
            </div>
            <template v-else>
                <div class="dialog-warn-block">一个组织内只存在一个超级管理员，请谨慎操作</div>
                <div style="padding: 20px 64px 10px 64px">
                    <el-form size="medium" label-position="top">
                        <el-form-item label="管理员移交至" required class="margin-b-20">
                            <el-select v-model="transferDialog.form.name" filterable remote reserve-keyword :remote-method="queryRemoteUsers" :loading="transferDialog.searchLoading" placeholder="请输入关键词搜索" clearable style="width: 100%;">
                                <template v-for="item in transferDialog.users">
                                    <el-option :key="item.memberId" :value="item.memberId" :label="item.alias">
                                        <div class="flex flex-align-center">
                                            <el-avatar :size="20" :src="item.avatar" icon="el-icon-user"></el-avatar>
                                            <span class="margin-l-5">{{item.alias}}</span>
                                        </div>
                                    </el-option>
                                </template>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="您的角色变更为" required class="margin-b-20">
                            <el-select v-model="transferDialog.form.role" placeholder="请选择角色" clearable style="width: 100%">
                                <template v-for="item in roleOptions">
                                    <el-option :key="item.roleId" :value="item.roleId" :label="item.roleName" v-if="item.roleId !== 2"></el-option>
                                </template>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
            </template>
            <div slot="footer" class="text-center" v-if="transferDialog.submited">
                <el-button type="primary" size="mini" round @click="transferDialog.show = false; $router.go(0);">确定</el-button>
            </div>
            <div slot="footer" class="text-center margin-b-15" v-else>
                <el-button size="mini" plain round @click="transferDialog.show = false">取消</el-button>
                <el-button type="primary" size="mini" round :loading="transferDialog.loading" @click="submitTransferDialogForm">移交</el-button>
            </div>
        </el-dialog>
        <el-dialog title="审核人员" :visible.sync="auditDialog.show" destroy-on-close width="430px" top="20px">
            <el-form label-position="top">
              <el-form-item label="角色" required>
                <el-select v-model="auditDialog.form.role" style="width: 100%;" placeholder="选择角色">
                    <template v-for="item in roleOptions">
                        <el-option :key="item.roleId" :value="item.roleId" :label="item.roleName" v-if="item.roleId !== 2"></el-option>
                    </template>
                </el-select>
              </el-form-item>
              <el-form-item label="姓名">
                <el-input v-model="auditDialog.form.memName" disabled placeholder="输入姓名"></el-input>
              </el-form-item>
              <el-form-item label="手机号">
                <el-input v-model="auditDialog.form.memPhone" disabled placeholder="输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="邮箱">
                <el-input v-model="auditDialog.form.memEmail" disabled placeholder="输入邮箱"></el-input>
              </el-form-item>
              <el-form-item label="所属团队" required class="margin-b-20">
                    <el-select v-model="auditDialog.form.teamList" placeholder="选择团队" multiple collapse-tags-tooltip :max-collapse-tags="3" style="width: 100%">
                    <el-option v-for="item in teamOptions" :key="item.value" :value="item.value" :label="item.text"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="text-center" slot="footer">
                <el-button type="danger" size="mini" round :loading="auditDialog.loading" @click="refuseApplyEvent">拒绝</el-button>
                <el-button type="primary" size="mini" round :loading="auditDialog.loading" @click="acceptApplyEvent">通过</el-button>
            </div>
        </el-dialog>
        <el-dialog title="联系方式" :visible.sync="contactDialog.show" destroy-on-close width="430px" top="20px">
            <div class="flex-column flex-align-center">
                <el-avatar :size="60" :src="contactDialog.user.avatar" icon="el-icon-user" class="fonts-36"></el-avatar>
                <div class="margin-t-10 text-weight-600">{{contactDialog.user.alias}}</div>
            </div>
            <div class="flex margin-y-20">
                <div class="flex-1 flex-column flex-align-center">
                    <div class="contact-type-block"><svg-icon name="ib-phone"></svg-icon></div>
                    <div class="margin-t-16">{{contactDialog.user.phone || '—'}}</div>
                    <a href="javascript:;" class="margin-t-10" @click="copyText(contactDialog.user.phone)">复制</a>
                </div>
                <div class="flex-1 flex-column flex-align-center">
                    <div class="contact-type-block"><svg-icon name="ib-letter"></svg-icon></div>
                    <div class="margin-t-16">{{contactDialog.user.email || '—'}}</div>
                    <a href="javascript:;" class="margin-t-10" @click="copyText(contactDialog.user.email)" v-if="contactDialog.user.email">复制</a>
                </div>
            </div>
        </el-dialog>
        <el-dialog :title="createTeamDialog.edit ? '编辑团队' : '新建团队'" :visible.sync="createTeamDialog.show" destroy-on-close @closed="handlerCreateTeamDialogClose" width="430px" top="20px">
            <div class="padding-x-20">
                <el-form size="medium" label-position="top">
                    <el-form-item label="团队名称" required class="margin-b-20">
                        <el-input v-model="createTeamDialog.form.name" placeholder="请输入团队名称" clearable></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="text-center">
                <el-button size="mini" plain round @click="createTeamDialog.show = false">取消</el-button>
                <el-button type="primary" size="mini" round :loading="createTeamDialog.loading" @click="createTeamDialogForm">保存</el-button>
            </div>
        </el-dialog>
        <el-dialog :title="createMemberOfferDialog.edit ? '编辑成员报价' : '新增成员报价'" :visible.sync="createMemberOfferDialog.show" @closed="handlerCreateMemberOfferDialogClose" destroy-on-close width="700px" top="20px">
            <el-form label-position="top" size="small">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="选择成员" required>
                        <el-select v-model="createMemberOfferDialog.form.memberId" @change="selectMemberEvent" :disabled="createMemberOfferDialog.edit" filterable style="width: 100%;">
                            <template v-for="item in createMemberOfferDialog.memberOptions">
                                <el-option :key="item.memberId" :value="item.memberId" :label="item.alias"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                        <el-form-item label="手机号">
                            <el-input v-model="createMemberOfferDialog.form.phone" disabled placeholder="选择成员后自动填充"></el-input>
                        </el-form-item>
                    </el-col>
                  <!-- <el-col :span="12">
                        <el-form-item label="所属团队" required>
                            <el-select v-model="createMemberOfferDialog.form.teamId" :disabled="createMemberOfferDialog.edit" placeholder="选择团队" style="width: 100%;">
                                <template v-for="item in createMemberOfferDialog.teamOptions">
                                    <el-option :key="item.teamId" :value="item.teamId" :label="item.teamName"></el-option>
                                </template>
                            </el-select>
                        </el-form-item>
                    </el-col> -->
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="邮箱">
                            <el-input v-model="createMemberOfferDialog.form.email" disabled placeholder="选择成员后自动填充"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                    <el-form-item label="角色">
                        <el-select v-model="createMemberOfferDialog.form.roleId" disabled placeholder="选择成员后自动填充" style="width: 100%;">
                            <template v-for="item in roleOptions">
                                <el-option :key="item.roleId" :value="item.roleId" :label="item.roleName"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="任务类型" required>
                        <el-select v-model="createMemberOfferDialog.form.taskType" placeholder="任务类型" style="width: 100%;">
                            <template v-for="item in createMemberOfferDialog.taskTypeOptions">
                                <el-option :key="item.value" :value="item.value" :label="item.text"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                    <el-form-item label="语言方向" required>
                        <div class="flex">
                            <div class="flex-1">
                                <el-select v-model="createMemberOfferDialog.form.sourceLang" filterable placeholder="源语言" style="width: 100%;">
                                    <template v-for="(item,key) in LANGUAGE_DICT">
                                        <el-option :key="key" :value="key" :label="item.name" :disabled="searchForm.target === item.key"></el-option>
                                    </template>
                                </el-select>
                            </div>
                            <div class="margin-x-10 fonts-18">
                                <svg-icon name="ib-right"></svg-icon>
                            </div>
                            <div class="flex-1">
                                <el-select v-model="createMemberOfferDialog.form.targetLang" filterable placeholder="目标语言" style="width: 100%;">
                                    <template v-for="(item,key) in LANGUAGE_DICT">
                                        <el-option :key="key" :value="key" :label="item.name" :disabled="createMemberOfferDialog.form.sourceLang === item.key"></el-option>
                                    </template>
                                </el-select>
                            </div>
                            <!-- <div class="margin-l-10">
                                <el-button plain icon="el-icon-check"></el-button>
                            </div> -->
                        </div>
                        <!-- <div class="margin-t-10">
                            <template v-for="i in 20">
                                <el-tag type="success" closable @close="deleteLanguageTagEvent">
                                    <span>中文</span> > <span>英文</span>
                                </el-tag>
                            </template>
                        </div> -->
                    </el-form-item>
                  </el-col>
                    <el-col :span="12">
                        <el-form-item label="任务单价" required>
                            <el-input-number v-model="createMemberOfferDialog.form.price" :precision="2" :step="0.1" :min="0" :max="10" controls-position="right" placeholder="输入单价" style="width: 100%;"></el-input-number>
                        </el-form-item>
                    </el-col>
                    
                </el-row>
              
            </el-form>
            <div slot="footer" class="text-center">
                <el-button size="mini" plain round @click="createMemberOfferDialog.show = false">取消</el-button>
                <el-button type="primary" size="mini" round :loading="createMemberOfferDialog.loading" @click="submitCreateMemberOfferDialogForm">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="showImportDialog" title="批量导入" destroy-on-close width="612px" top="20px">
            <div class="fonts-12 line-height-1.5 text-center">报价数据需按照模板格式提交，导入前请<a :href="`https://nextcat-prod.oss-accelerate.aliyuncs.com/files/public/%E6%89%B9%E9%87%8F%E6%96%B0%E5%A2%9E%E6%8A%A5%E4%BB%B7%E6%A8%A1%E6%9D%BF.xlsx?_ttt=${new Date().getTime()}`">下载模板</a></div>
            <div class="margin-t-15">
                <!-- :size="1000" -->
                <upload-oss ref="importUploadFileDom"
                    :multiple="false"
                    :params="{}"
                    accept=".xlsx"
                ></upload-oss>
            </div>
            <div class="text-center" slot="footer">
                <el-button plain round size="small" @click="showImportDialog = false">取消</el-button>
                <el-button type="primary" round size="small" :loading="importDialogLoading" @click="submitImportDialogEvent">确定</el-button>
            </div>
        </el-dialog>
        <alert-dialog ref="deleteAlertDom" message="确定要删除吗？" type="confirm" buttonName="确认删除" buttonType="danger" @confirmEvent="submitDeleteEvent"></alert-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import qs from 'querystring';
import config from '@/utils/config';
import tools from '@/utils/tools';
import { teamApi, commonApi } from '@/utils/api';
import AlertDialog from '@/components/AlertDialog';
import UploadOss from '@/components/UploadOss';
export default {
    components: { AlertDialog, UploadOss, },
    data () {
        return {
            config, 
            tools,
            teamId: null,
            list: [],
            pageSize: 20,
            pageNumber: 1,
            total: 0,
            searchTimer: null,
            loading: false,
            searchForm: {
                kw: '',
                source: '',
                target: '',
                role: '',
                status: '',
                team: '',
            },
            queryType: '',
            roleOptions: [],
            teamOptions: [],
            // statusOptions: [{id:'inviting', name: '邀请中'},{id:'joined', name: '已加入'}],
            inviteDialog: {
                show: false,
                url: '',
            },
            editDialog: {
                show: false,
                loading: false,
                submited: false,
                type: 'new', // edit
                form: {
                    name: '',
                    role: '',
                    phone: '',
                    email: '',
                    teamList: [],
                }
            },
            transferDialog: {
                show: false,
                loading: false,
                submited: false,
                searchLoading: false,
                searchTimer: null,
                users: [],
                form: {
                    name: '',
                    role: '',
                },
            },
            contactDialog: {
                show: false,
                user: {},
            },
            deleteItem: null, 
            activeFilter: 'TEAM',
            auditDialog: {
                show: false,
                loading: false,
                form: {
                    role: '',
                    memName: '',
                    memPhone: '',
                    memEmail: '',
                    teamList: [],
                }
            },
            createTeamDialog: {
                show: false,
                loading: false,
                edit: false,
                team: null,
                form: {
                    name: '',
                }
            },
            createMemberOfferDialog: {
                show: false,
                loading: false,
                edit: false,
                offerId: null,
                form: {
                    memberId: '',
                    roleId: '',
                    phone: '',
                    email: '',
                    sourceLang: '',
                    targetLang: '',
                    taskType: '',
                    price: 0,
                    // teamId: '',
                },
                memberOptions: [],
                taskTypeOptions: [],
                teamOptions: [],
            },
            showImportDialog: false,
            importDialogLoading: false,
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userData,
            activeOrg: state => state.org.activeOrg,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        })
        
    },
    methods: {
        initPage(){
            let url = teamApi.queryMemberList;
            let postData = {
                keyword: this.searchForm.kw,
                pageNo: this.pageNumber,
                pageSize: this.pageSize,
                roleId: this.searchForm.role,
                actionStatus: this.searchForm.status,
                // teamId: this.searchForm.team,
            };
            this.loading = true;
            this.$ajax.post(url, postData).then(res => {
                if (res.status === 200) {
                    this.list = res.data.records;
                    this.total = res.data.total;
                    this.teamId = res.data.teamId;
                }
            }).finally(() => {
                this.loading = false;
            })
        },
        initTeamPage() {
            // TODO 初始化团队列表
            let url = teamApi.queryTeamList;
            let postData = {
                pageNo: this.pageNumber,
                pageSize: this.pageSize,
                keyword: this.searchForm.kw,
            }
            this.loading = true;
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200) {
                    this.list = res.data.records;
                }
            }).finally(() => {
                this.loading = false;
            })

        },
        initRoleOptions() {
            this.$ajax.get(teamApi.queryRolesOptions).then(res => {
                if(res.status === 200) {
                    this.roleOptions = res.data;
                }
            })
        },
        queryTeamOptions() {
            let url = commonApi.queryTeamFilterOptions;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.teamOptions = res.data.teamFilters;
                    this.createMemberOfferDialog.taskTypeOptions = res.data.taskFilters
                    // console.log(this.teamOptions);
                }
            });
        },
        inviteBtnEvent(){
            this.inviteDialog.show = true;
        },
        handleCurrentChange(val){
            this.pageNumber = val;
            if(this.activeFilter === 'MY'){
                this.initPage();
            }else if(this.activeFilter === 'AUDIT') {
                this.initAuditPage();
            }else if(this.activeFilter === 'TEAM') {
                this.initTeamPage();
            }else if(this.activeFilter === 'OFFER') {
                this.initMemberOfferPage();
            }
        },
        inputSearchEvent(val){
            let self = this;
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(()=>{
                self.queryType = '';
                if(val){
                    self.queryType = 'search';
                }
                this.pageNumber = 1;
                if(this.activeFilter === 'MY'){
                    self.initPage();
                }else if(this.activeFilter === 'AUDIT') {
                    self.initAuditPage();
                }else if(this.activeFilter === 'OFFER') {
                    self.initMemberOfferPage();
                }
            },500);
        },
        selectSearchEvent(val){
            this.queryType = '';
            if(val){
                this.queryType = 'search';
            }
            this.pageNumber = 1;
            if(this.activeFilter === 'MY'){
                this.initPage();
            }else if(this.activeFilter === 'OFFER') {
                this.initMemberOfferPage();
            }
        },
        editMemberInfo(item){
            let url = teamApi.queryMemberDetail;
            this.$ajax.post(url, {organizationId: item.organizationId, memberId: item.memberId}).then(res => {
                if(res.status === 200){
                    this.editDialog.form = {
                        name: res.data.alias,
                        role: res.data.roleId,
                        phone: res.data.phone,
                        email: res.data.email,
                        teamMemId: res.data.memberId,
                        teamList: res.data.teamList.map(item => item.teamId.toString()),
                    };
                    this.editDialog.form.teamId = this.editDialog.form.teamList[0],
                    // console.log(this.editDialog.form.teamList);
                    // console.log(this.editDialog.form.teamId);
                    this.editDialog.show = true;
                }
            });
        },
        submitEditDialogForm(){
            if(!this.editDialog.form.name){
                this.$message.error('请输入姓名');
                return;
            }
            if(!this.editDialog.form.role){
                this.$message.error('请选择角色');
                return;
            }
            if(!this.editDialog.form.phone){
                this.$message.error('请输入手机号');
                return;
            }else{
                if(!tools.checkPhone(this.editDialog.form.phone)){
                    this.$message.error('请输入正确的手机号');
                    return;
                }
            }
            if(!this.editDialog .form.email){
                this.$message.error('请输入邮箱');
                return;
            }else{
                if(!tools.checkEmail(this.editDialog.form.email)){
                    this.$message.error('请输入正确的邮箱');
                    return;
                }
            }
            if(this.editDialog.form.teamList.length < 1){
                this.$message.error('请选择所属团队');
                return;
            }
            let url = teamApi.updateMember;
            
            let postData = {
                alias: this.editDialog.form.name,
                roleId: this.editDialog.form.role,
                phone: this.editDialog.form.phone,
                email: this.editDialog.form.email,
                memberId: this.editDialog.form.teamMemId,
                teamIds: this.editDialog.form.teamList,
            };
            this.editDialog.loading = true;
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200){
                    this.$message.success('修改成功');
                    this.editDialog.show = false;
                    this.initPage();
                }
            }).finally(()=>{
                this.editDialog.loading = false;
            });
        },
        handlerEditDialogClose(){
            this.editDialog = this.$options.data().editDialog;
        },
        handlerTransferDialogClose(){
            this.transferDialog = this.$options.data().transferDialog;
        },
        transferBtnEvent(cUser){
            let url = teamApi.queryMemberList;
            let postData = {
                keyword: '',
                pageNo: 1,
                pageSize: 1000,
                roleId: '',
                actionStatus: 'joined',
            };
            this.$ajax.post(url, postData).then(res => {
                if (res.status === 200) {
                    this.transferDialog.users = res.data.records.filter(item => item.memRoleId !== 2);
                    this.transferDialog.show = true;
                }
            })
        },
        submitTransferDialogForm(){
            if (!this.transferDialog.form.name) {
                this.$message.error('请选择管理员');
                return;
            }
            if (!this.transferDialog.form.role) {
                this.$message.error('请选择变更后的角色');
                return;
            }

            let url = teamApi.transferRole;
            let postData = {
                newAdminMid: this.transferDialog.form.name,
                newRoleId: this.transferDialog.form.role,
            };
            this.transferDialog.loading = true;
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200) {
                    this.transferDialog.submited = true;
                }
            }).finally(() => {
                this.transferDialog.loading = false;
            });
        },
        queryRemoteUsers(query){
            let self = this;
            if(this.transferDialog.searchTimer){
                clearInterval(this.transferDialog.searchTimer);
                this.transferDialog.searchTimer = null;
            }
            if(query !== ''){
                this.transferDialog.searchLoading = true;
                this.transferDialog.searchTimer = setInterval(()=>{

                }, 500);
            }
        },
        
        queryMemberInfo(item){
            let url = teamApi.queryMemberDetail;
            this.$ajax.post(url, {organizationId: item.organizationId, memberId: item.memberId}).then(res => {
                if(res.status === 200){
                    this.contactDialog.user = res.data;
                    this.contactDialog.show = true;
                }
            })
        },

        submitDeleteEvent(){
            if(this.activeFilter === 'MY'){
                this.$ajax.post(teamApi.deleteMember, {memberId: this.deleteItem.memberId}).then(res => {
                    if(res.status === 200){
                        this.$message.success('删除成功');
                        this.initPage();
                    }
                })
            }else if(this.activeFilter === 'AUDIT') {
                this.$ajax.delete(this.$ajax.format(teamApi.deletApplyMember, {id: this.deleteItem.id})).then(res => {
                    if(res.status === 200){
                        this.$message.success('删除成功');
                        this.initAuditPage();
                    }
                })
            }else if(this.activeFilter === 'TEAM'){
                this.$ajax.get(`${teamApi.deleteTeam}?teamId=${this.deleteItem.teamId}`).then(res => {
                    if(res.status === 200){
                        this.$message.success('删除成功');
                        this.initTeamPage();
                        this.queryTeamOptions();
                    }
                })
            }else if(this.activeFilter === 'OFFER'){
                this.deleteMemberOfferBtnEvent(this.deleteItem);
            }
        },
        handleMoreCommand(command, item){
            switch(command){
                case 'view':
                    if(this.activeFilter === 'TEAM') {
                        this.searchForm.team = item.teamId + '';
                        this.activeFilter = 'MY';
                        this.initPage();
                    }else {
                        if(this.activeFilter === 'AUDIT') return;
                        if(!this.$checkPermission('team:myTeamMembers:info')){
                            return;
                        }
                        this.queryMemberInfo(item);
                    }
                    break;
                case 'edit':
                    if(this.activeFilter === 'TEAM') {
                        this.editTeamInfo(item);
                    }else {
                        this.queryTeamOptions();
                        this.editMemberInfo(item);
                    }
                    break;
                case 'grant': 
                    this.transferBtnEvent(item)
                    break;
                case 'delete':
                    this.deleteItem = item;
                    this.$refs.deleteAlertDom.setShow(true);
                    break;
            }
        },
        copyText(text){
            let self = this;
            this.$copyText(text).then(function (e) {
                self.$message.success('已复制到剪切板');
            }, function (e) {
                self.$message.error('复制到剪切板失败，请重试');
            })
        },
        initAuditPage(){
            let url = `${teamApi.queryApplyList}?pageSize=${this.pageSize}&pageNo=${this.pageNumber}&teamId=${this.userInfo.initTeam.teamId}`;
            this.loading = true;
            this.$ajax.get(url).then(res => {
                if(res.status == 200) {
                    this.total = res.data.total;
                    this.list = res.data.records;
                }
            }).finally(() => {
                this.loading = false;
            })
        },
        createTeamBtnEvent() {
            //TODO 创建团队
            this.createTeamDialog.show = true;
            this.createTeamDialog.edit = false;
        },
        handlerCreateTeamDialogClose() {
            this.createTeamDialog = this.$options.data().createTeamDialog;
        },
        createTeamDialogForm() {
            console.log(this.activeOrg);
            // return
            if(!this.createTeamDialog.form.name) {
                this.$message.error('请输入团队名称');
                return;
            }
            let url = teamApi.createTeam;
            let postData = {
                teamName: this.createTeamDialog.form.name,
                organizationId: this.activeOrg.organizationId,
            }
            if(this.createTeamDialog.edit) {
                url = teamApi.updateTeam;
                postData = {
                    teamId: this.createTeamDialog.team.teamId,
                    organizationId: this.createTeamDialog.team.orgId,
                    newTeamName: this.createTeamDialog.form.name,
                }
            }
            this.createTeamDialog.loading = true;
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200) {
                    this.$message.success('创建成功');
                    this.createTeamDialog.show = false;
                    if(this.activeFilter === 'TEAM') {
                        this.pageNumber = 1;
                        this.initTeamPage();
                        this.queryTeamOptions();
                    }
                }
            }).finally(() => {
                this.createTeamDialog.loading = false;
            })
        },
        editTeamInfo(item){
            this.createTeamDialog.edit = true;
            this.createTeamDialog.team = item;
            this.createTeamDialog.form.name = item.teamName;
            this.createTeamDialog.show = true;
        },
        filterClickEvent(val) {
            this.list = [];
            this.total = 0;
            this.pageNumber = 1;
            this.searchForm = this.$options.data().searchForm;
            this.queryTeamOptions();
            if(val.name === 'MY'){
                this.initPage();
            }else if(val.name === "AUDIT") {
                this.initAuditPage();
            }else if(val.name === 'TEAM'){
                this.initTeamPage();
            }else if(val.name === 'OFFER'){
                this.initMemberOfferPage();
            }
        },
        generateInviteLink() {
            let url = teamApi.queryInviteCode;
            this.$ajax.post(url, {teamId: this.userInfo.initTeam.teamId}).then(res => {
                if(res.status === 200) {
                    let code = res.data;
                    let link = `${location.origin}/team/invite/${code}`;
                    this.inviteDialog.url = link;
                }
            })
        },
        copyShareLink(){
            let self = this;
            let text = `${this.activeOrg.organizationName}邀请你加入企业协作，快点击链接登录LanguageX平台，申请加入组织吧~\n${this.inviteDialog.url}`;
            this.$copyText(text).then(function (e) {
                self.$message.success('已复制到剪切板');
            }, function (e) {
                self.$message.error('复制到剪切板失败，请重试');
            })
        },
        async auditBtnEvent(user) {
            let url = this.$ajax.format(teamApi.queryApplyDetail, {id: user.id});
            const res = await this.$ajax.get(url);
            this.auditDialog.form = res.data;
            this.auditDialog.show = true;
        },
        acceptApplyEvent() {
            if (!this.auditDialog.form.role) {
                this.$message.error('请选择角色');
                return;
            };
            if (this.auditDialog.form.teamList.length < 1) {
                this.$message.error('请选择团队');
                return;
            }
            let url = teamApi.acceptApply;
            let postData = {
                record: this.auditDialog.form.id,
                role: this.auditDialog.form.role,
                teamIds: this.auditDialog.form.teamList,

            };
            // console.log(this.auditDialog.form.teamList);
            this.auditDialog.loading = true;
            this.$ajax.put(url, postData).then(res => {
                if(res.status == 200) {
                    this.$message.success('已通过');
                    this.auditDialog.show = false;
                    this.initAuditPage();
                }
            }).finally(() => {
                this.auditDialog.loading = false;
            })
        },
        refuseApplyEvent() {
            let url = teamApi.refuseApply;
            let postData = {
                record: this.auditDialog.form.id,
            };
            this.auditDialog.loading = true;
            this.$ajax.put(url, postData).then(res => {
                if(res.status == 200) {
                    this.$message.success('已拒绝');
                    this.auditDialog.show = false;
                    this.initAuditPage();
                }
            }).finally(() => {
                this.auditDialog.loading = false;
            })
        },
        refreshPage(){
            if(this.activeFilter === 'MY') {
                this.initPage();
            }else if(this.activeFilter === 'AUDIT'){
                this.initAuditPage();
            }else if(this.activeFilter === 'TEAM'){
                this.initTeamPage();
            }else if(this.activeFilter === 'OFFER'){
                this.initMemberOfferPage();
            }
        },
        teamNameStyle(teamCount) {
            if (teamCount >= 3) {
                return {
                display: 'flex',
                maxWidth: '120px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
                };
            } else {
                return {
                display: 'flex',
                maxWidth: 'none',
                textOverflow: 'initial',
                whiteSpace: 'normal',
                overflow: 'visible'
                };
            }
        },
        initMemberOfferPage() {
            let url = teamApi.queryMemberOfferList;
            let postData = {
                keyword: this.searchForm.kw,
                pageNo: this.pageNumber,
                pageSize: this.pageSize,
                role: this.searchForm.role,
                teamId: this.searchForm.team,
            };
            this.loading = true;
            this.$ajax.get(url + `?${qs.stringify(postData)}`).then(res => {
                if (res.status === 200) {
                    this.list = res.data.records;
                    this.total = res.data.total;
                }
            }).finally(() => {
                this.loading = false;
            })

        },
        handlerCreateMemberOfferDialogClose() {
            this.createMemberOfferDialog.edit = false;
            this.createMemberOfferDialog.offerId = null;
            this.createMemberOfferDialog.form = this.$options.data().createMemberOfferDialog.form;
        },
        async createOfferBtnEvent() {
            // TODO 跳转至创建offer页面
            this.createMemberOfferDialog.show = true;
            this.createMemberOfferDialog.edit = false;
            let memberRes = await this.$ajax.post(teamApi.queryMemberList, {
                keyword: '',
                pageNo: 1,
                pageSize: 1000,
                roleId: '',
                actionStatus: 'joined'
            });
            this.createMemberOfferDialog.memberOptions = memberRes.data.records;
        },
        async editMemberOfferBtnEvent(item) {
            await this.createOfferBtnEvent();
            this.selectMemberEvent(item.memberId);
            this.createMemberOfferDialog.edit = true;
            this.createMemberOfferDialog.offerId = item.quotationId;
            this.createMemberOfferDialog.form.memberId = item.memberId;
            // this.createMemberOfferDialog.form.teamId = item.teamId;
            this.createMemberOfferDialog.form.sourceLang = item.sourceLang;
            this.createMemberOfferDialog.form.targetLang = item.targetLang;
            this.createMemberOfferDialog.form.price = item.price;
            this.createMemberOfferDialog.form.taskType = item.taskType;
        },
        deleteMemberOfferBtnEvent(item) {
            let url = this.$ajax.format(teamApi.deleteMemberOffer, {id: item.quotationId});
            this.$ajax.delete(url).then(res => {
                if(res.status === 200) {
                    this.$message.success('删除成功');
                    this.initMemberOfferPage();
                }
            })
        },
        selectMemberEvent(val) {
            let member = this.createMemberOfferDialog.memberOptions.filter(memberItem => memberItem.memberId === val)[0];
            console.log(val, member);
            this.createMemberOfferDialog.form.phone = member.phone;
            this.createMemberOfferDialog.form.email = member.email;
            this.createMemberOfferDialog.form.roleId = member.roleId;
            // this.createMemberOfferDialog.teamOptions = member.teamList;

        },
        deleteLanguageTagEvent () {

        },
        submitCreateMemberOfferDialogForm () {
            if (!this.createMemberOfferDialog.form.memberId) {
                this.$message.error('请选择成员');
                return;
            }
            if (!this.createMemberOfferDialog.form.sourceLang || !this.createMemberOfferDialog.form.targetLang) {
                this.$message.error('请选择源语言和目标语言');
                return;
            }
            if (!this.createMemberOfferDialog.form.taskType || this.createMemberOfferDialog.form.taskType.length === 0) {
                this.$message.error('请选择任务类型');
                return;
            }
            // if (!this.createMemberOfferDialog.form.teamId) {
            //     this.$message.error('请选择团队');
            //     return;
            // }
            let postData = {
                ...this.createMemberOfferDialog.form,
            };
            this.createMemberOfferDialog.loading = true;
            if(this.createMemberOfferDialog.edit) {
                let url = teamApi.updateMemberOffer;
                postData.quotationId = this.createMemberOfferDialog.offerId;
                this.$ajax.put(url, postData).then(res => {
                    if(res.status == 200) {
                        this.$message.success('修改成功');
                        this.createMemberOfferDialog.show = false;
                        this.initMemberOfferPage();
                    }
                }).finally(() => {
                    this.createMemberOfferDialog.loading = false;
                })
            }else {
                let url = teamApi.createMemberOffer;
                this.$ajax.post(url, postData).then(res => {
                    if(res.status == 200) {
                        this.$message.success('创建成功');
                        this.createMemberOfferDialog.show = false;
                        this.initMemberOfferPage();
                    }
                }).finally(() => {
                    this.createMemberOfferDialog.loading = false;
                })
            }
        },
        submitImportDialogEvent() {
            let files = this.$refs.importUploadFileDom.getOssFiles();
            console.log(files);
            if(files.length === 0) {
                this.$message.error('请确认已上传文件并上传成功');
                return;
            }
            this.importDialogLoading = true;
            let url = teamApi.batchImportQuotation;
            this.$ajax.post(url, {files}).then(res => {
                if(res.status === 200) {
                    this.$message.success('导入成功');
                    this.showImportDialog = false;
                    this.initMemberOfferPage();
                }
            }).finally(() => {
                this.importDialogLoading = false;
                
            })
        },
    },
    mounted(){
        if(this.$checkPermission(`team:listTeam`)) {
            this.activeFilter = 'TEAM';
        }else if(this.$checkPermission(`team:myTeamMembers`)){
            this.activeFilter = 'MY';
        }else if(this.$checkPermission(`team:reviewTeamMember`)){
            this.activeFilter = 'AUDIT';
        }else if(this.$checkPermission(`team:quotation`)){
            this.activeFilter = 'OFFER';
        }
        
        if(this.activeFilter === 'MY') {
            this.initPage();
        }else if(this.activeFilter === 'AUDIT'){
            this.initAuditPage();
        }else if(this.activeFilter === 'TEAM'){
            this.initTeamPage();
        }else if(this.activeFilter === 'OFFER'){
            this.initMemberOfferPage();
        }
        this.initRoleOptions();
        this.queryTeamOptions();
    },
}
</script>
<style lang="scss" scoped>
.team-index-page {
    .card-list{
        margin: 0 60px;
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
        .item {
            background-color: #FFFFFF;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            padding: 15px 20px;
            display: flex;
            cursor: pointer;
            .team-name{
                max-width: 50px;
                margin-right: 5px;
                font-size: 12px;
                color: #808080;
                
             }
             .team-name-row{
                display: flex;
                width: 120px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
             }
            .email-name{
                min-height: 13px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            .transfer-btn{
                width: 50px;
                height: 24px;
                border-radius: 20px;
                box-sizing: border-box;
                border: 1px solid #cccccc;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #F56C6C;
                font-size: 12px;
                cursor: pointer;
            }
            .audit-btn {
                width: 50px;
                height: 24px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #EBF0FF;
                color: #2979FF;
                font-size: 12px;
                cursor: pointer;
            }
            .status {
                width: 50px;
                height: 24px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                background-color: #EBF0FF;
                color: #2979FF;
                &.success{
                    background-color: #DDF3EB;
                    color: #00B670;
                }
                &.audit_3 {
                    background-color: #FFF0EE;
                    color: #EB7C7C;
                }
            }
            .action{
                width: 50px;
                height: 24px;
                background-color: #EEEEEE;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                
                cursor: pointer;
                > .user{
                    display: block;
                    color: #333333;
                }
                > .more {
                    display: none;
                    color: #333333;
                }
            }
            &:hover {
                .action{
                    > .user{
                        display: none;
                    }
                    > .more {
                        display: block;
                    }
                }
            }
        }
    }
    ::v-deep .el-table{
        &::before {
            height: 0;
            width: 0;
            bottom: -10000px;
            background-color: transparent;
        }
        th {
            padding: 10px 0;
            font-size: 14px;
            color: #999999;
        }
        .el-table__row {
            td {
                vertical-align: middle;
                background-color: #ffffff;
                font-size: 14px;
                padding: 10px 0;
                
                .cell {
                    .el-button--text {
                        font-size: 14px;
                        svg {
                            font-size: 18px;
                        }
                    }
                }
            }
            &:hover {
                td {
                    background-color: #fff;
                }
            }
        }
    }
    .invite-dialog-content{
        text-align: center;
        line-height: 1.5;
        ::v-deep .el-input-group__append{
            background-color:#000000;
            color: #fff;
            border: 1px solid #000000;
        }
    }
}
.contact-type-block{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #F2F2F2;
    font-size: 20px;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
<style>
.el-tag {
    margin: 0 5px;
}
</style>
